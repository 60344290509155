$user-icon-font-multiplier: .75;
$user-icon-width: 2.75em;

.user-icon {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: $user-icon-font-multiplier * 1rem;
    width: $user-icon-width;
    height: $user-icon-width;
    border-radius: 100%;
    color: #fff;
    background: theme-color(primary);
    font-weight: bold;

    &.user-icon-xs {
        font-size: .4rem;
    }

    &.user-icon-sm {
        font-size: .5rem;
    }

    &.user-icon-lg {
        font-size: 1.2rem;
    }

    .bubble {
        position: absolute;
        top: 0;
        right: 0;
        color: theme-color(primary);
        background: #fff;
        font-size: .5em;
    }
}
