/**
 * Workaround for https://github.com/angular/angular/pull/42608
 * TODO These rules belong to spinner.component.scss
 *      When the above fix is released, move these rules back
 */
app-spinner {
    .circular {
        -webkit-animation: rotate 2s linear infinite;
        animation: rotate 2s linear infinite;
    }

    .path {
        -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        stroke: theme-color();
    }

    @-webkit-keyframes rotate {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes rotate {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124;
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124;
        }
    }

    @-webkit-keyframes color {
        100%, 0% {
            stroke: theme-color(brand-2);
        }
        40% {
            stroke: theme-color(brand-3);
        }
        66% {
            stroke: theme-color(brand-2);
        }
        80%, 90% {
            stroke: theme-color(brand-3);
        }
    }

    @keyframes color {
        100%, 0% {
            stroke: theme-color(brand-2);
        }
        40% {
            stroke: theme-color(brand-3);
        }
        66% {
            stroke: theme-color(brand-2);
        }
        80%, 90% {
            stroke: theme-color(brand-3);
        }
    }
}
