.bubble {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    font-size: 8px;
    $size: 2em;
    width: $size;
    height: $size;
    text-align: center;
    border-radius: 50%;
    font-weight: 400;
    letter-spacing: -.5px;
    background: theme-color(danger);
    color: #fff;
    @include raised(6px, 1.5);

    &.badge-brand-3 {
        @extend .badge-brand-3;
    }
}
