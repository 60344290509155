@import 'variables';

@media print {
    app-task-panel, app-floating-actions, app-sidebar, ngx-loading-bar {
        display: none !important;
    }

    .portal-wrapper {
        &.has-blur {
            display: none;
        }
    }

    slide-panel-host {
        &.active {
            .wrapper {
                position: relative;
                height: auto;
                width: auto !important;
                margin-right: 0;

                .panel {
                    position: relative;
                    height: auto;
                    width: auto;

                    .panel-header, .panel-body {
                        padding-left: 0;
                        padding-right: 0;

                        .action-icon {
                            display: none;
                        }
                    }

                    .panel-header {
                        height: auto;
                        padding-bottom: 2rem;
                    }

                    &:not(.last) {
                        display: none;
                    }
                }

                & > button, & > div > button {
                    display: none;
                }
            }

            .overlay {
                display: none;
            }
        }
    }
}

@media print and (monocrhome) {
    .text-warning {
        color: inherit !important;
    }
}
