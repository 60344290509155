.page-heading {
    display: flex;
    align-items: center;
    min-height: $header-height;
    padding-top: 4px;
    padding-bottom: 4px;

    app-breadcrumbs, .title {
        font-size: 18px;
        font-weight: 100;
        color: rgba(0, 0, 0, .66);
        background: transparent;
        padding: 0;
        margin: 0 auto 0 0;
        cursor: default;
        display: block;
    }

    .xng-breadcrumb-list {
        display: flex;

        .xng-breadcrumb-separator {
            font-size: 0;

            &:before {
                display: block;
                color: inherit;
                opacity: .5;
                content: '';
                font-size: 12px;
                width: 1em;
                height: 1em;
                padding: 0;
                margin: 0 .5em;
                background: no-repeat 50% 50%;
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='currentColor' d='M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z'></path></svg>");
                background-size: 50%;
            }
        }

        .xng-breadcrumb-item {
            a {
                color: inherit;
                text-decoration: none !important;

                &:hover {
                    color: theme-color(primary);
                }
            }

            label {
                margin: 0;
                color: inherit;
            }

            & + .breadcrumb-item {
                padding-left: 0;
            }
        }
    }

    .add-on {
        display: flex;
        align-items: center;
        min-width: 0;
        margin-left: map-get($spacers, 3);

        select, .body-select {
            min-width: 80px;

            &.w-sm {
                min-width: 40px;
            }
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        &.break-addon-to-new-line-#{$breakpoint} {
            @include media-breakpoint-down($breakpoint) {
                flex-wrap: wrap;

                .add-on {
                    min-width: 100%;
                    order: 3;
                    margin-left: 0;
                }

                .responsive-filter-btn {
                    order: 2;
                }
            }
        }
    }
}

.section-head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 2em 0 12px;
    color: $body-ui-color;

    .title {
        font-size: 1rem;
        margin: 0 1em 0 0;

        &:not(.emphasized) {
            opacity: .66;
        }

        a {
            color: inherit;

            &:hover {
                color: $link-hover-color;
                text-decoration: none;
            }
        }
    }

    .add-on {
        display: flex;
        margin-left: auto;
        align-items: center;

        label {
            margin: 0;
        }

        .btn {
            margin-top: -.5em;
            margin-bottom: -.5em;
        }
    }

    &.small {
        margin: 1em 0 6px;

        .title {
            font-size: 12px;
        }
    }

    &, &.small {
        &:first-child:not(.keep-spacing) {
            margin-top: 0;
        }
    }

    &.spacious {
        min-height: $input-height;
    }
}

.strong {
    font-weight: 700;
}

$data-heading-opacity: .5;
$data-heading-color: rgba($body-color, $data-heading-opacity);
.data-heading {
    .title {
        font-size: 18px;
        font-weight: 300;
        color: $data-heading-color;
    }

    .subtitle, .sub-subtitle {
        font-size: 11px;
        font-weight: 700;
        color: $data-heading-color;
        line-height: 15px;

        .dummy-pin, .pin {
            margin-right: 6px;

            &:not(:first-child) {
                margin-left: 6px;
            }
        }

        .light {
            font-weight: 400;
        }
    }

    .sub-subtitle {
        font-weight: 400;
    }
}

.list-category {
    color: $data-heading-color;
    font-size: 10px;
    text-transform: uppercase;
}

.card-body > .data-heading {
    &.w-border {
        margin: $card-spacer-x*-1 $card-spacer-x*-1;
        padding: $card-spacer-x $card-spacer-x $card-spacer-y;
        border-left: $ui-neutral-border;
    }
}

$card-tab-border-color: theme-color(primary);

.nav-tabs:not(.card-tabset .nav-tabs, .card-tabset) {
    .nav-item {
        .nav-link {
            color: theme-color(primary);
            font-weight: 500;
            border: 0;
            margin: 0 0 1px;

            &:hover {
                background: theme-color(light);
                border: 0;
                margin: 0 0 1px;
            }

            &.active {
                color: $body-color;
                border: 0;
                border-bottom: 2px solid $card-tab-border-color;
                background: transparent;
                margin: 0 0 -2px;
            }

            &.disabled {
                color: $nav-link-disabled-color;
                cursor: not-allowed;

                &:hover {
                    background: transparent;
                }
            }
        }
    }
}

.card-tab, .card-tabset.nav-tabs .nav-item .nav-link {
    border-color: $card-tab-border-color;
    border-style: solid;
    border-width: 0;
    padding: 1em 20px;
    background: #fff;
    @include transition($transition-base);
    cursor: pointer;
    font-weight: 500;
    border-radius: 0;
    color: $body-color;

    &:not(.active) {
        opacity: .5;

        &:hover {
            opacity: .75;
        }
    }

    & {
        @include apply-border-width(3px);
    }

    &.small {
        font-size: 8px;
        @include apply-border-width(2px);
    }

    &.large {
        font-size: 14px;
        @include apply-border-width(4px);
    }
}

.card-tabset {
    &.nav-tabs {
        border: 0;

        .nav-item {
            flex: 1;
        }
    }

    &:not(.card-tabset-body).nav-tabs {
        background: theme-color(light);
    }
}

.card-tabs {
    background: theme-color(light);
}

.card-tabs-body, .card-tabset-body {
    background: transparent;

    .card-tab, &.nav-tabs .nav-item .nav-link {
        background: transparent;

        &:hover:not(.active) {
            background: #fff;
        }
    }

    .col:not(:last-child) .card-tab {
        border-right-width: 0;
    }
}

.nav-tabs .nav-item .nav-link {
    padding-left: .6rem;
    padding-right: .75rem;
    height: 100%;
}

.body-tabset {
    &.nav-tabs {
        margin: 0 0 20px;
    }

    &:not(:first-child).nav-tabs {
        margin-top: 12px;
    }
}

.nav-pills {
    margin-bottom: 1em;
}

.slide-panel-arsana {
    .nav-tabs:not(.card-tabset .nav-tabs, .card-tabset) .nav-item .nav-link {
        &:not(.active):not(.disabled) {
            color: theme-color(arsana-link);

            &:hover {
                background: theme-color(arsana-light);
            }
        }

        &.active  {
            border-color: theme-color(arsana-deep);
            color: #000;
        }
    }

    .nav-link:not(.disabled) {
        color: theme-color(arsana-link);
    }

    .nav-pills {
        .nav-link.active, .show > .nav-link {
            background-color: theme-color(arsana-deep);
            color: rgba(0, 0, 0, .8);
        }
    }

}

.panel-body {
    app-patient-summary:first-child {
        margin-top: -1em;
    }
}
