@import "@ng-select/ng-select/themes/default.theme.css";

label:not(.xng-breadcrumb-trail) {
    font-weight: $font-weight-normal;
}

.lock-icon {
    cursor: pointer;
    float: right;
}

.invalid-feedback {
    display: block;
}

@keyframes focus-pulsating {
  0%, 1000% {box-shadow: 0 0 0 0 rgba(theme-color(primary), .25);}
  50% {box-shadow: 0 0 0 $input-focus-width rgba(theme-color(primary), .25);}
}

.form-control, .custom-select {
    > .icon, > * > .icon {
        margin: 0 .25em 0 -.25em;
        color: theme-color('primary');
    }

    &.focus {
        border-color: $input-focus-border-color;
        box-shadow: $input-focus-box-shadow;
        background: $input-focus-bg;
        color: $input-focus-color;
    }

    &.disabled {
        background: $input-disabled-bg;
    }

    &.listening {
        animation: focus-pulsating 2s linear infinite;
    }

    &:invalid, &.is-invalid, &.ng-invalid {
        &:not(.ng-untouched) {
            $invalid-bg: theme-color-level("danger", -12);
            background: $invalid-bg;
            border-color: theme-color(danger);

            &:focus, &.focus {
                box-shadow: 0 0 0 $input-focus-width rgba(theme-color(danger), .25);
            }

            .ng-select {
                &, &.ng-select-opened {
                    & > .ng-select-container {
                        background: $invalid-bg;
                    }
                }
            }
        }
    }
}

.form-section {
    border-top: 1px solid $input-border-color;
}

.form-array-item {
    position: relative;
    border: 1px solid $card-border-color;
    border-radius: $border-radius;
    padding: 1em;
    margin-bottom: $form-group-margin-bottom;

    .control-btns {
        position: absolute;
        top: 0;
        right: 1em;

        .btn {
            margin-top: -1px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    & > .form-group:last-child {
        margin-bottom: 0;
    }
}

@mixin input-group-styling() {
    flex-grow: 1;
    &:not(:first-child) {
        margin-left: -$input-border-width;
    }
    & + input {
        margin-left: -$input-border-width;
    }
    &:not(:first-child) .ng-select-container {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    &:not(:last-child) .ng-select-container {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

$body-input-padding-h: .33em;
$body-input-padding-v: .5rem;

.body-select, .body-input {
    height: auto;
    min-height: $input-height;
    display: inline-flex;
    align-items: center;
    font-size: 1em;
    background: transparent;
    padding: 0 $body-input-padding-h;
    border: 0;
    border-bottom: 1px solid $border-color;
    border-radius: 0;
    box-shadow: none;
    transition: $transition-base;
    color: $body-ui-color;
    width: auto;
    vertical-align: middle;
    cursor: default;

    .icon {
        color: theme-color(primary);
        position: relative;

        &:first-child {
            margin-right: .25em;
        }
    }

    &:not([disabled]) {
        &:hover, &:focus, &.focus {
            color: $body-ui-hover-color;
            background-color: rgba(#fff, .8);
            box-shadow: 0 2px 6px rgba(0, 0, 0, .066);
        }
    }

    &:focus {
        outline: none;
    }

    &.w-md {
        width: 10rem;
    }

    &.w-sm {
        width: 3rem;
    }

    &.sm {
        font-size: $font-size-sm;
        height: $input-height-sm;
        min-height: $input-height-sm;
    }

    &[disabled] {
        opacity: .5;
    }
}

$body-select-padding-r: 1.33em;

.body-select {
    padding-right: $body-select-padding-r;
    background: no-repeat 100% 50%;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'><path fill='rgba(0,0,0,.2)' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'></path></svg>");
    background-size: 1em;
    white-space: nowrap;
    border-radius: 0;
    appearance: none;

    &.dropdown-toggle:after {
        display: none;
    }
}

.body-dropdown-wrapper {
    position: relative;

    .body-dropdown-content {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 10;
        background: #fff;
        box-shadow: 0 8px 24px rgba(0, 0, 0, .1);
        border: 1px solid $border-color;
        border-radius: $border-radius;
    }

    & > .body-select {
        display: flex;
    }

    &.dropdown-align-left {
        .body-dropdown-content {
            right: auto;
            left: 0;
        }
    }
}

.ng-select {
    color: $input-color;

    input {
        color: inherit;
    }

    &, &.ng-select-single {
        .ng-select-container {
            min-height: $input-height;
            font-size: $font-size-base;
            line-height: $input-btn-line-height;
            padding: $input-padding-y 0;
            border-radius: $input-border-radius;
            border-color: $input-border-color;

            .ng-value-container {
                margin: -$input-padding-y 0;
                min-width: 0;

                .ng-value {
                    max-width: 100%;
                }

                &, & .ng-input {
                    padding-left: $input-padding-x !important;
                }
            }
        }

        &.ng-select-single .ng-select-container .ng-value-container .ng-value {
            flex-grow: 1;
        }

        &.ng-select-focused, &.ng-select-opened {
            .ng-select-container {
                border-color: theme-color(primary);
                box-shadow: 0 0 0 $input-focus-width rgba(theme-color(primary), .25);
            }
        }

        .input-group-lg &, &.ng-select-lg {
            .ng-select-container {
                height: $input-height-lg;
                font-size: $font-size-lg;
                line-height: $input-btn-line-height-lg;
                padding: $input-padding-y-lg 0;
                border-radius: $input-border-radius-lg;

                .ng-value-container {
                    margin: -$input-padding-y-lg 0;

                    &, & .ng-input {
                        padding-left: $input-padding-x-lg !important;
                    }
                }
            }
        }

        .input-group & {
            @include input-group-styling();
        }

        .form-control-sm & {
            .ng-select-container {
                height: $input-height-sm;
                font-size: $font-size-sm;
                line-height: $input-btn-line-height-sm;
                padding: $input-padding-y-sm 0;
                border-radius: $input-border-radius-sm;

                .ng-value-container {
                    margin: -$input-padding-y-sm 0;

                    &, & .ng-input {
                        padding-left: $input-padding-x-sm !important;
                    }
                }

                .ng-arrow-wrapper {
                    padding-right: 0;
                    width: 20px;
                }
            }
        }

        &.purified, &.body-select {
            .ng-select-container {
                border-radius: 0;
                border: 0;
                height: auto;
                min-height: auto;
                padding: 0;
                background: transparent;
                overflow: visible;

                .ng-value-container {
                    margin: 0;
                    padding: 0;

                    &, & .ng-input {
                        top: 0;
                        padding-left: 0 !important;
                    }

                    .ng-placeholder {
                        top: auto;
                        padding-left: 0;
                        color: $input-placeholder-color;
                    }
                }

                &:hover {
                    box-shadow: none;
                }
            }

            &.ng-select-focused, &.ng-select-opened {
                .ng-select-container {
                    box-shadow: none;
                }
            }

            &.ng-select-multiple {
                .ng-select-container {
                    .ng-value-container {
                        margin-bottom: -5px;

                        .ng-input {
                            padding-bottom: 5px;
                        }
                    }
                }
            }
        }

        &.body-select {
            display: flex;
            padding: 0;
            align-items: stretch;
            justify-content: stretch;

            .ng-select-container {
                flex: 1 1 auto;
                padding: $input-padding-y $input-padding-x;

                .ng-value-container {
                    .ng-input {
                        top: $input-padding-y;
                        padding-left: $input-padding-x !important;
                    }
                }
            }

            .ng-arrow-wrapper {
                display: none;
            }
        }

        &.ng-invalid, &.invalid {
            &:not(.ng-untouched):not(.purified) {
                .ng-select-container {
                    $invalid-bg: theme-color-level("danger", -12);
                    background: $invalid-bg;
                    border-color: theme-color(danger);
                }

                &.ng-select-focused, &.ng-select-opened {
                    .ng-select-container {
                        box-shadow: 0 0 0 $input-focus-width rgba(theme-color(danger), .25);
                    }
                }
            }
        }

        &.ng-select-disabled .ng-select-container {
            background-color: $input-disabled-bg;
        }
    }

    &.ng-select-wrap-text, .ng-select-wrap-text & {
        .ng-select-container .ng-value-container .ng-value {
            white-space: normal !important;
        }
    }

    &.ng-select-multiple {
        .ng-select-container .ng-value-container .ng-value {
            display: flex;
            overflow: hidden;
            white-space: normal !important;

            .ng-value-label {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: normal !important;
}

.input-group app-select-autocomplete {
    @include input-group-styling();
}

@mixin advanced-input-input-spacing-v($p) {
    padding-top: $p;
    padding-bottom: $p;

    textarea, .textarea, ng-select, input, datepicker-select, .value-caption {
        &:not(.form-control):not(.custom-select) {
            margin-top: -$p;
            margin-bottom: -$p;
            padding-top: $p;
            padding-bottom: $p;
        }
    }
}

app-advanced-input, .advanced-input {
    &.form-group {
        display: block;
    }
}

app-combo-input, .combo-input, app-advanced-input:not(.form-group), .advanced-input {
    &, &.form-control {
        display: flex;
        height: auto;
    }

    label {
        color: theme-color(primary);
    }

    &.is-invalid {
        label {
            color: theme-color(danger);
        }
    }

    &.form-control:not(.form-control-original) {
        border-left: 0;
        border-right: 0;
        margin-bottom: -1px;

        &:not(.first) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        &:not(.last) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &.first {
            margin-top: -1px;
        }

        //@include advanced-input-input-spacing-v($input-padding-y);
        @include advanced-input-input-spacing-v(map-get($spacers, 3));

        &.form-control-sm {
            @include advanced-input-input-spacing-v(map-get($spacers, 2));
        }
    }

    @for $i from 1 through 5 {
        &.p-#{$i}, &.py-#{$i} {
            @include advanced-input-input-spacing-v(map-get($spacers, $i));
        }
    }

    &.custom-select {
        display: flex;
        padding-right: 0;
        background-image: none;
        height: auto;
        min-height: $custom-select-height;

        &.ng-invalid {
            &:not(.ng-untouched) {
                $invalid-bg: theme-color-level("danger", -12);
                background: $invalid-bg;
                border-color: theme-color(danger) !important;
            }

            &.focus {
                &:not(.ng-untouched) {
                    box-shadow: 0 0 0 $input-focus-width rgba(theme-color(danger), .25);
                }
            }
        }
    }

    .label-icon {
        margin-right: .66em;
    }

    label {
        width: 9em;
        flex: none;
        margin-right: 1em;
        margin-bottom: 0;

        &.wide {
            width: 12em;
        }

        &.narrow {
            width: auto;
        }
    }

    textarea, .textarea, ng-select, input, datepicker-select, .value-caption {
        &:not(.form-control):not(.custom-select) {
            border: 0;
            padding: 0;
            margin: 0;
            outline: 0;
            width: 100%;
            min-width: 30px;
            box-shadow: none;
            background: transparent;
            color: $input-color;
            font: inherit;
            flex: 1 1 auto;
            resize: none;

            &::placeholder {
                color: $input-placeholder-color;
            }
        }
    }

    & > .textarea {
        box-sizing: content-box;
    }

    datepicker-select {
        .purified {
            min-height: 1.5em;
        }
    }

    app-hint {
        flex: 0 0 auto;
        margin-left: 1em;
        margin-top: 2px;
    }

    &.body-select {
        padding-left: 0;
        padding-right: 0;

        @include advanced-input-input-spacing-v($body-input-padding-v);

        textarea, .textarea, ng-select, & > input, datepicker-select, .value-caption {
            &:not(.form-control):not(.custom-select) {
                margin-bottom: calc(-#{$body-input-padding-v} - 1px);
                padding-left: $body-input-padding-h;
                padding-right: $body-input-padding-h;
            }
        }

        ng-select, datepicker-select {
            &:not(.form-control):not(.custom-select) {
                padding-right: $body-select-padding-r;
            }
        }

        .ng-arrow-wrapper {
            display: none;
        }
    }

    .placeholder {
        width: 0;
        max-width: 0;
        overflow: visible;
        white-space: nowrap;
        color: $input-placeholder-color;
    }

    &.simplified {
        padding: 0 !important;
        border: 0;

        label {
            color: $black !important;
            font-weight: 700;
            width: unset;
            margin: 0 !important;
        }
    }

    &.body-select, &.body-input {
        color: $body-ui-color;

        ng-select.ng-select {
            color: inherit;

            .ng-select-container {
                color: inherit;
            }
        }
    }
}

.form-control.form-control-original {
    .input-group > &, .input-group-sm > &, .input-group-lg > & {
        height: auto;
        min-width: 8em;
    }

    .input-group-sm > & {
        .action-icon.md {
            font-size: 1em !important;
            margin-right: -.5rem;
        }
    }
}

.textarea {
    &, &:focus, &.focus {
        outline: none;
    }

    &:empty:before {
        content: attr(placeholder);
        color: $input-placeholder-color;
    }

    .input-group > & {
        height: unset !important;
        min-width: 8em;
    }
}

.text-placeholder {
    color: $input-placeholder-color;
}

app-physician-select {
    .wide-input, .ng-select .ng-dropdown-panel {
        min-width: 14em !important;
    }
}

.ng-value-overflow-visible {
    .ng-select.ng-select-single .ng-select-container .ng-value-container {
        overflow: visible;

        .ng-value {
            overflow: visible;
        }
    }
}

.no-number-spinners {
    /* Firefox */
    -moz-appearance: textfield;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
