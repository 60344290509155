$base-z-index: $overlay-z-index;

.overlay {
    position: fixed;
    z-index: $base-z-index;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(theme-color(dark), .5);
}
