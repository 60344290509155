@use "sass:math";

@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/mixins/transition";

@mixin raised($y: 12px, $darkness: .5) {
    box-shadow: 0 math.div($y, 3) $y rgba(0, 0, 0, $darkness*.2);
}

$ys: 8px, 12px, 24px, 36px, 48px, 60px;

@for $i from 1 through length($ys) {
    .raised-#{$i} {
        @include raised(nth($ys, $i));
    }
    .raised-light-#{$i} {
        @include raised(nth($ys, $i), .25);
    }
    .raised-dark-#{$i} {
        @include raised(nth($ys, $i), 1);
    }
}

.raised {
    @include raised();
}

.raised-light {
    @include raised(nth($ys, 2), .25);
}

.raised-dark {
    @include raised(nth($ys, 2), 1);
}

.w-0 {
    width: 0;
}

.floating {
    border: 1px solid $card-border-color;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .12), 0 16px 48px rgba(0, 0, 0, .12);
}

.row-gap-1 {
    row-gap: 1rem;
}

.transition-base {
    @include transition($transition-base);
}

@mixin flex-fit-parent-v() {
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: column;
}

.flex-fit-parent-v {
    @include flex-fit-parent-v();
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint);

    @include media-breakpoint-up($breakpoint) {
        @for $i from 1 through $grid-columns {
            .ml-neg-col#{$infix}-#{$i} {
                margin-left: -(math.div($i, $grid-columns)*100%);
            }
        }
    }
}

@mixin apply-border-width($w) {
    border-top-width: $w;
    &.bottom-align {
        border-width: 0;
        border-bottom-width: $w;
    }
    &.left-align {
        border-width: 0;
        border-left-width: $w;
    }
    &.right-align {
        border-width: 0;
        border-right-width: $w;
    }
}

@mixin triangle($direction: 0, $color: #fff, $width: 12px, $height: 6px) {
    width: 0;
    height: 0;
    border-style: solid;
    $border-widths: ();
    $border-colors: ();
    @each $i in 0, 1, 2, 3 {
        $rem: ($i - $direction)%4;
        $bw: if($rem == 0, 0, if($rem == 1 or $rem == 3, $width*0.5, $height));
        $bc: if($rem == 2, $color, transparent);
        $border-widths: append($border-widths, $bw);
        $border-colors: append($border-colors, $bc);
    }
    border-width: $border-widths;
    border-color: $border-colors;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

@media print {
    .overflow-print-visible {
        overflow: visible !important;
    }

    .print-full-width {
        max-width: 100%;
    }
}

.display-contents {
    display: contents !important;
}

@keyframes pulsating {
  0%   {transform: scale(1)}
  25%  {transform: scale(.909)}
  75%  {transform: scale(1.1)}
  100% {transform: scale(1)}
}

.pulsating:not(.ng-animating) {
  animation: pulsating 1s linear infinite;
}

@keyframes anti-pulsating {
  0%   {transform: scale(1)}
  25%  {transform: scale(1.1)}
  75%  {transform: scale(.909)}
  100% {transform: scale(1)}
}

.anti-pulsating:not(.ng-animating) {
  animation: anti-pulsating 1s linear infinite;
}

.blurred {
    filter: blur(3px);
}

.bg-white-50 {
    background-color: rgba(255,255,255, 0.5);
}
