@mixin btn-circle($d) {
    width: $d;
    height: $d;
    padding: 0;
    border-radius: 50%;
    &.m-absolute {
        margin: -$d*0.5;
    }
}

.btn-xs {
    @include button-size($input-btn-padding-y-xs, $input-btn-padding-x-xs, $font-size-xs, $input-btn-line-height-xs, $btn-border-radius);
}

.btn-circle {
    @include btn-circle($diameter-btn-circle);
}

.btn-circle-lg {
    @include btn-circle($diameter-btn-circle-lg);
}

.btn-circle-xl {
    @include btn-circle($diameter-btn-circle-xl);
}

.btn-circle-sm {
    @include btn-circle($diameter-btn-circle-sm);
}

.btn-circle-xs {
    @include btn-circle($diameter-btn-circle-xs);
    font-size: $font-size-xs;
}

.btn {
    fal, far, fas {
        &.first {
            margin-left: -.33em;
        }

        &.last {
            margin-right: -.33em;
        }
    }
}

.w-hover-panel {
    position: relative;
    cursor: default;

    .hover-panel-cnt {
        position: absolute;
        top: -1rem;
        left: -1rem;
        visibility: hidden;
        opacity: 0;
        transform: translateY(6px);
        transition: $transition-base;
    }

    &:hover {
        .hover-panel-cnt {
            visibility: visible;
            opacity: 1;
            transform: none;
        }
    }

    &.w-hover-panel-right {
        .hover-panel-cnt {
            left: auto;
            right: -1rem;
        }
    }

    &.w-hover-panel-middle {
        .hover-panel-cnt {
            top: 50%;
            margin-top: -($input-btn-line-height*1rem + 2*$input-btn-padding-y) * 0.5;
            left: .5em;
        }

        &.w-hover-panel-right {
            .hover-panel-cnt {
                left: auto;
                right: .5em;
            }
        }
    }
}

.floating-actions {
    position: fixed;
    z-index: $overlay-z-index + 5;
    right: 0;
    bottom: 0;
    margin: $fab-spacing;
    display: flex;
    flex-direction: column;

    .btn {
        flex: none;
        display: block;
        margin-top: 8px;
        @extend .raised-dark-2;

        &:hover {
            @extend .raised-dark-1;
        }
    }

    &.reverse {
        flex-direction: column-reverse;
    }
}

.btn-darken {
    color: inherit;
    background: rgba(0, 0, 0, 0);

    &:hover {
        color: inherit;
        background: rgba(0, 0, 0, .15);
    }
}

.action-icon {
    $color: theme-color(primary);
    color: $color;
    cursor: pointer;
    transition: $transition-base;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: 0;
    background: transparent;
    flex: none;
    position: relative;

    &.dropdown-toggle:after {
        display: none;
    }

    &, &:active, &:focus {
        outline: none;
    }

    &[disabled] {
        color: rgba($btn-link-disabled-color, .33);
        cursor: default;
    }

    &:not([disabled]) {
        &:hover, &:focus, &.active {
            color: $color;
            background: rgba($color, .15);
        }
    }

    &.xs {
        font-size: $font-size-xs;
    }

    &.sm {
        font-size: $font-size-sm;
    }

    &.md {
        font-size: $font-size-base;
    }

    &.lg {
        font-size: $font-size-lg;
    }

    &.xl {
        font-size: $font-size-xl;
    }

    &.light {
        color: theme-color(brand-3);

        &[disabled] {
            opacity: .5;
        }

        &:not([disabled]) {
            &:hover, &:focus, &.active {
                color: lighten(theme-color(brand-3), 10%);
                background: rgba(theme-color(brand-3), .15);
            }
        }
    }

    .bubble {
        position: absolute;
        background: theme-color(brand-2);
        top: -.75em;
        right: -.75em;
    }
}

.action-icon-cnt {
    display: flex;
    align-items: center;
    border-radius: 1.5em;
    border: 1px solid rgba($border-color, .5);
}

@each $color, $value in $theme-colors {
    .action-icon-#{$color} {
        color: $value;

        &[disabled] {
            opacity: .5;
        }

        &:not([disabled]) {
            &:hover, &:focus, &.active {
                //color: lighten($value, 10%);
                color: $value;
                background: rgba($value, .15);
            }
        }

        .bubble {
            background: $value;
        }
    }
}

.action-panel-v {
    width: 2em;

    &.xs {
        font-size: $font-size-xs;
    }

    &.sm {
        font-size: $font-size-sm;
    }

    &.md {
        font-size: $font-size-base;
    }

    &.lg {
        font-size: $font-size-lg;
    }

    &.xl {
        font-size: $font-size-xl;
    }
}
