$stroke-color: $card-border-color;
$label-color: rgba(0, 0, 0, .66);

.chart-wrapper {
    min-height: 350px;

    &.xs {
        min-height: 80px;
        height: 80px;
        max-width: 240px;
    }

    &.sm {
        min-height: 160px;
        height: 160px;
        max-width: 480px;
    }
}

.nav-label {
    color: $label-color;
    font-size: 8px;
    margin: 0;
    padding: 8px 0;
    text-transform: uppercase;
}

.chart {
    text {
        fill: $label-color;
    }

    .axis {
        > path {
            stroke: $stroke-color;
        }

        > .tick {
            > line {
                stroke: $stroke-color;
            }

            > text {
                fill: $label-color;
            }
        }
    }

    g.y-axis {
        display: none;
    }

    g.y2-axis {
        .domain {
            display: none;
        }
    }

    .line-series {
        path {
            stroke-width: 2px;
        }
    }

    .column-series {
        fill-opacity: .8;
        stroke-opacity: 0;
    }
}

.ngx-charts .chart .grid-panel.odd rect {
    fill: rgba(0, 0, 0, .02);
}

.tooltip-line {
    stroke: rgba(0, 0, 0, .12);
}

ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip, .data-tooltip {
    @extend .floating;
    cursor: default;
    font-family: inherit;
    color: rgba(#000, .5);
    background: #fff;
    text-align: left;
    $tooltip-padding: 14px;
    border-radius: $border-radius;

    .caption {
        display: block;
        text-align: left;
        font-weight: 400;
        color: $body-color;
        padding: 0 $tooltip-padding;
        margin: -.33em 0 .5em;
    }

    // Alternate tooltip markers style
    padding: $tooltip-padding 0;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: $ui-neutral-border-width;
        height: 100%;
        background: $ui-neutral-border-color;
    }

    > .abscissas {
        padding: 0 $tooltip-padding;
    }

    .tooltip-item, .tooltip-table-row {
        font-size: 10px;
        position: relative;
        padding: 2px $tooltip-padding;

        &:not(:last-child) {
            margin-bottom: 4px;
        }

        .y-value {
            font-size: 2em;
            font-weight: 300;
            color: $body-color;
            line-height: 1.2em;
        }

        .tooltip-item-color {
            position: absolute;
            left: 0;
            top: 0;
            width: 3px;
            height: 100%;
            border-radius: 0;
        }
    }

    .tooltip-item {
        .data-caption {
            display: block;
            padding: 0;
            margin-bottom: 2px;
        }

        .y-value {
            display: block;
            padding-left: 0;
        }
    }

    .tooltip-table {
        th {
            font-size: .66em;
            line-height: 1em;
        }
    }

    .tooltip-caret {
        display: none;
    }

    &.w-caret:after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 12px 0 0;
        border-color: #ffffff transparent transparent transparent;
        opacity: .66;
    }

    .footnote {
        padding: $tooltip-padding;
        font-size: 8px;
        line-height: 1.2;

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.data-tooltip-wrapper {
    position: relative;
    cursor: default;

    .data-tooltip {
        visibility: hidden;
        opacity: 0;
        min-width: 250px;
        position: absolute;
        bottom: 100%;
        left: -2 * $ui-neutral-border-width;
        margin-bottom: 2 * $ui-neutral-border-width;
        @include transition($transition-base);
        transform: translateY(12px);
    }

    &:hover {
        .data-tooltip {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.no-legend {
    .chart-legend {
        display: none;
    }
}

ngx-charts-chart .chart-legend {
    margin-top: 2em;

    .legend-title {
        display: none;
    }

    .legend-labels {
        background: none;
    }
}

.simple-data {
    font-size: 10px;
    border-color: theme-color(primary);
    border-style: solid;
    border-width: 0;
    padding: 1em 20px;

    .label {
        opacity: .5;
        line-height: 1;
        height: 2.2em;
        margin-bottom: -.2em;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .data {
        font-size: 2.5em;
        line-height: 1.2;
        font-weight: 300;

        small {
            font-size: .5em;
        }

        .unit {
            font-size: .66em;
            margin-left: .1em;
        }

        &:not(:last-child) {
            margin-bottom: .2em;
        }
    }

    .change {
        opacity: .5;

        &.text-success, &.text-danger, &.text-warning {
            opacity: 1;
        }
    }

    .change-positive {
        color: theme-color(success);
        opacity: 1;
    }

    .change-negative {
        color: theme-color(danger);
        opacity: 1;
    }

    .foot {
        opacity: .5;
        font-size: .8em;
    }

    .action-caption {
        color: $link-color;
    }

    &.simple-data-control {
        @include transition($transition-base);
        cursor: pointer;

        &:hover {
            box-shadow: 0 8px 24px rgba(0, 0, 0, .1);
            opacity: 1;
            background: #fff;

            .action-caption {
                color: $link-hover-color;
            }
        }

        &.inactive {
            opacity: .33;

            &:hover {
                opacity: .66;
            }
        }
    }

    & {
        @include apply-border-width(3px);
    }

    &.small {
        font-size: 8px;
        @include apply-border-width(2px);
    }

    &.large {
        font-size: 14px;
        @include apply-border-width(4px);
    }

    &.border-no {
        border-style: none !important;
    }
}

.filter-data-tiles {
    .simple-data {
        margin-bottom: 1px;
        padding-top: 6px;
        padding-bottom: 6px;

        &:hover {
            position: relative;
        }
    }

    .nav-label:not(:first-child) {
        margin-top: -1px;
    }
}

.filter-data-tiles-h {
    margin-bottom: 12px;

    .simple-data {
        padding: 6px 8px;
    }

    .col, *[class], *[class^='col-'] {
        .simple-data {
            height: 100%;
        }

        &:not(:last-child) {
            .simple-data {
                margin-right: 1px;
            }
        }
    }
}

.border-color-1 {
    border-color: theme-color(primary);
}

.border-color-2 {
    border-color: theme-color(secondary);
}

.border-color-3 {
    border-color: theme-color(tertiary);
}

.legend {
    display: flex;
    justify-content: flex-end;
    font-size: 10px;
    color: $gray-600;

    .legend-item {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-right: 12px;
        }

        .pin {
            margin-right: 4px;
        }
    }
}

.ngx-charts {
    .reference-area {
        opacity: 0;
    }

    .refline-path {
        stroke: theme-color(danger);
        opacity: .5;
    }

    .refline-label {
        fill: theme-color(danger);
        opacity: .5;
    }
}
