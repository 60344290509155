$review-section-padding: 2.5em;

.review-section {
    display: block;
    padding: 1em 1em 2em $review-section-padding;
    border-top: 1px solid $gray-300;

    .review-section-icon {
        margin-left: -$review-section-padding;
    }
}

.review-section-icon {
    float: left;
    margin: 1em .5em 1em 0;
    color: theme-color(primary);
}

.review-section-title, .review-section-subtitle {
    font-weight: 700;
    font-size: 1em;
    margin: 1em 0;
}

.review-section-subtitle {
    color: theme-color(secondary);
}

app-task-editor {
    .nav-cnt, .nav-paging-btns {
        padding-left: .75em !important;
        padding-right: .75em !important;
    }

    .nav-paging-btns {
        margin-top: -1em !important;
        padding-bottom: 1em;
    }

    .review-section {
        padding-top: .5em;
        margin-left: .75em;
        margin-right: .75em;
    }

    .review-section-icon {
        padding-left: map-get($spacers, 2);
    }

    app-assessment-plan.review-section {
        margin-left: 0;
        margin-right: 0;
        padding-left: $review-section-padding + .75em;
        padding-right: 1.75em;
        border-bottom: 1px solid $gray-300;
    }
}

.message-bubble {
    .review-section {
        font-size: $small-font-size;
        padding-top: .5em;
        padding-bottom: 1em;

        .review-section-title, .review-section-subtitle, .review-section-icon {
            margin-top: .5em;
            margin-bottom: .5em;
        }

        small, .small {
            font-size: 100%;
        }
    }

    app-advanced-input, .advanced-input {
        &.form-control {
            font-size: 1em;
            padding-top: 1em;
            padding-bottom: 1em;
        }
    }
}
