@use "sass:math";

.portal-wrapper {
}

app-sidebar {
    position: fixed;
    width: $sidebar-width;
    height: 100%;
    z-index: 3;

    ul {
        padding-top: 100px;
    }
}

.main {
    margin-left: $sidebar-width;
    z-index: 0;
    background: $body-bg;
}

.main, app-sidebar {
    //transition: filter .2s ease-in-out, transform .2s ease-in-out;
    &.blurred {
        filter: blur(3px);
    }
}

slide-panel-host.blurred {
    .wrapper:not(.stack-top) {
        filter: blur(2px);
    }
}

app-sidebar {
    &.blurred {
        box-shadow: -40px 0 0 40px theme-color(dark);
    }

    .has-blur &:not(.blurred) {
        @include raised(24px, 2);
    }
}

slide-panel-host.active ~ app-floating-actions .overlay {
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 100%;
        width: $sidebar-width;
        height: 100%;
        background: inherit;
    }
}

.main-inner {
    padding: $content-padding-top $content-padding-h $content-padding-bottom;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.portable-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60vh;
    max-width: 80vh;
    align-self: center;
    flex-grow: 1;
    padding-top: $content-padding-bottom;
}

$task-panel-right-margin: calc(#{$fab-spacing * 2} + #{$diameter-btn-circle-xl} - 10px);

.bottom-panel {
    left: $sidebar-width;
    --task-panel-right-margin: #{$task-panel-right-margin};
    width: calc(100% - var(--task-panel-right-margin) - #{$sidebar-width});
}

$portable-header-height: 52px;
.app-header {
    display: flex;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $portable-header-height;
    align-items: center;
    background: $gradient-1;
    color: #fff;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .1) inset, 0 1px 0 rgba(0, 0, 0, .08), 0 0 8px rgba(0, 0, 0, .12);

    app-logo {
        font-size: 1.5em;
        margin: 0 auto;
    }

    .portable-button {
        position: relative;
        padding: .5em;
        margin: 0 .5em;
        font-size: 18px;
        cursor: pointer;

        .bubble {
            position: absolute;
            top: .5em;
            right: 0;
        }
    }
}

app-user-status, app-search, .floating-panel-wrapper {
    position: fixed;
    top: 0;
    left: $sidebar-width;
    width: calc(100% - #{$sidebar-width});
    height: 100%;
    display: flex;
    z-index: $overlay-z-index + 3;
    @include media-breakpoint-down(md) {
        left: 0;
        width: 100%;
    }
}

.full-height {
    height: calc(100vh - #{$content-padding-top} - #{$header-height} - #{$content-padding-bottom});
}

@include media-breakpoint-down(md) {
    .portal-wrapper {
        overflow-x: hidden;
    }
    app-sidebar {
        z-index: $overlay-z-index + 1;
        left: -$sidebar-width * 1.1;
        transition: all .6s cubic-bezier(0.19, 1, 0.22, 1);
        //transform: scale(1.1);
    }
    .main-inner {
        border-top: $portable-header-height solid transparent;
    }
    .main, .app-header {
        margin-left: 0;
        transition: all .6s cubic-bezier(0.19, 1, 0.22, 1);
    }
    .menu-open {
        app-sidebar {
            left: 0;
            @include raised(24px, 1);
            transform: scale(1);
        }

        .app-header, .main {
            margin-left: $sidebar-width * 0.25;
            margin-right: -$sidebar-width * 0.25;
        }
    }
    .full-height {
        height: calc(100vh - #{$content-padding-top} - #{$header-height} - #{$content-padding-bottom} - #{$portable-header-height});
    }
    .bottom-panel {
        left: 0;
        width: calc(100% - #{$task-panel-right-margin});
    }
}

@include media-breakpoint-up(lg) {
    .menu-overlay, .app-header {
        display: none;
    }
}
