@mixin gradient-bg($deg, $stretch: false, $color-1: $gradient-color-1, $color-2: $gradient-color-2) {
    $start: 0%;
    $end: 100%;
    @if $stretch {
        $start: -50%;
        $end: 150%;
    }
    background: linear-gradient($deg * 1deg, $color-1 $start, $color-2 $end) !important;
    border-color: rgba($color-1, .2) !important;
}

$degs: 0, 45, 90, 135, 180, 225, 270, 315;

@each $deg in $degs {
    .bg-gradient-primary-#{$deg} {
        @include gradient-bg($deg, true);
    }
}

.bg-gradient-primary {
    @extend .bg-gradient-primary-0;
}

.text-white {
    a {
        color: inherit !important;
    }
}
