.pin, .dummy-pin {
    font-size: 6px;
    width: 4em;
    height: 4em;
    line-height: 1em;
    border-radius: 50%;
    color: #fff;
    background: theme-color(primary);
    text-align: center;
    cursor: default;
    -webkit-print-color-adjust: exact;

    &.square {
        border-radius: 0;
    }

    &.small {
        font-size: 4px;
    }

    &.mini {
        font-size: 2px;
    }

    &.large {
        font-size: 12px;
    }
}

.dummy-pin {
    font-size: 4px;
    display: inline-block;
    vertical-align: middle;
}

.pin {
    display: flex;
    justify-content: center;
    align-items: center;

    .caption {
        position: relative;
        max-width: 100%;
        overflow: hidden;
    }

    &.action-pin {
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: inherit;
            transition: $transition-base;
            opacity: 0;
        }

        &:hover:before {
            transform: scale(1.66);
            opacity: .33;
        }
    }

    &.square {
        border-radius: 0;
    }

    .pin-icon {
        font-size: 2em;
    }
}

app-floor-plan {
    break-after: page;
}

@mixin pin-shadow() {
    box-shadow: 1px 0 3px rgba(0, 0, 0, .33);
}

.pin-shadow {
    @include pin-shadow();
}

.floor-plan {
    position: relative;

    .floor-plan-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .floor-plan-img {
        display: block;
        width: 100%;
        border-radius: 0 0 $card-inner-border-radius $card-inner-border-radius;
    }

    .position {
        position: absolute;
        width: 0;
        left: 0;
        transition: $transition-base, top 0s, right 0s, bottom 0s, left 0s;

        .pin {
            position: absolute;
            top: 0;
            left: 0;
            margin: -2em;
            @include pin-shadow();
        }

        .popover {
            display: none;
            position: absolute;
            width: 260px;
            top: 0;
            left: -130px;

            .arrow {
                left: 50%;
                margin: 0 -4px;
            }
        }

        &:hover > .popover {
            display: block;
        }

        &.hidden {
            opacity: 0;
            visibility: hidden;
        }
    }

    .dashed-line {
        stroke: rgba(0, 0, 0, .2);
        stroke-width: 1;
        stroke-dasharray: 4, 4;
    }

    @media print {
        display: block;
        .floor-plan-img {
            display: initial;
        }
    }
}
