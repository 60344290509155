$color-bar-w: 2px;
$color-bar-cell-padding-w: $color-bar-w;
$color-bar-cell-padding-h: 2px;

.color-bar {
    display: inline-flex;
    align-items: stretch;
    align-content: stretch;
    height: 1.2em;
    width: $color-bar-w;
    visibility: hidden;
    vertical-align: middle;

    &:after {
        visibility: visible;
        content: '';
        flex: 1;
        display: block;
        border-radius: $color-bar-w*0.5;
        background-color: inherit;
    }
}

.table {
    > tr:first-child, > tbody:first-child > tr:first-child, > thead:first-child > tr:first-child {
        > td, > th {
            border-top: 0;
        }
    }

    tr {
        position: relative;
    }

    .text-muted {
        color: rgba($body-color, .33);
    }

    .text-crossed {
        text-decoration: line-through;
    }

    .text-crossed:hover {
        text-decoration: none;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .color-bar-cell {
        padding: $color-bar-cell-padding-h $color-bar-cell-padding-w;
        height: 100%;
        position: relative;

        .color-bar {
            display: flex;
            position: absolute;
            top: 0;
            left: $color-bar-cell-padding-w;
            padding: $color-bar-cell-padding-h 0;
            height: 100%;
            min-height: 20px;
        }
    }

    [ng-reflect-ngb-tooltip]:not(a) {
        //cursor: help;
    }

    .hover-td {
        position: relative;

        &:hover {
            z-index: 1;
            @extend .raised-dark-3;
        }
    }

    .featured-td {
        z-index: 2;
        transform: scale(1.05);
        @extend .raised-dark-4;

        &:hover {
            z-index: 2;
            @extend .raised-dark-3;
        }
    }

    .table {
        background: transparent;
    }
}

.table-cool {
    border-spacing: 2px;
    border-collapse: separate;

    td, th {
        border: 0;
        border-radius: 2px;
    }
}

.table, .table-cool {
    .input-td {
        position: relative;
        padding: 0;

        .input {
            position: absolute;
            top: 0;
            left: 0;
            border: 0;
            background: #fff;
            width: 100%;
            height: 100%;
            border-radius: inherit;
            text-align: inherit;

            &:focus {
                z-index: 1;
            }
        }
    }
}

.no-color-bar-cell-padding {
    .color-bar-cell {
        padding: 0 $color-bar-cell-padding-w*0.5;
    }

    .color-bar {
        left: 0;
        padding: 0;
    }
}

.table-wrapper {
    overflow-x: auto;
}

table.no-padding {
    th, td {
        padding: 0 .5em 0 0;
        vertical-align: middle;
    }
}

.sticky-row {
    th, td {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 1;
    }
}
