.btn {
    cursor: pointer;

    &.disabled, &[disabled] {
        cursor: default;
    }
}

.btn-link {
    box-shadow: none;
    border: none;
}

a {
    cursor: pointer;
}
