.list-group {
    .list-group {
        margin: $list-group-item-padding-y -1*$list-group-item-padding-x -1*$list-group-item-padding-y;

        .list-group-item {
            border-left: 0;
            border-right: 0;

            &:first-child {
                border-radius: 0;
            }

            &:last-child {
                border-radius: 0;
                border-bottom: 0;
            }
        }
    }

    .list-group-item-head {
        display: flex;
        margin: -1*$list-group-item-padding-y -1*$list-group-item-padding-x -1*$list-group-item-padding-y;
        padding: 0;
        align-items: stretch;

        .action-wrapper {
            display: flex;
            flex: 1 1 auto;
            align-items: center;
            padding: $list-group-item-padding-y 0;
        }

        .dummy-pin {
            flex: 0 0 auto;
            margin: 0 4px;
        }

        .caption {
            flex: 1 1 auto;
        }

        .chevron-wrapper {
            border-left: 1px solid rgba(0, 0, 0, .05);
            display: flex;
            align-items: center;
            padding: 6px;
        }

        .chevron {
            transition: $transition-base;
        }

        &.open .chevron, .chevron-wrapper:hover .chevron {
            transform: rotate(90deg);
        }
    }

    .list-group-item {
        transition: $transition-base;
        user-select: none;

        &.passive {
            opacity: .33;
        }
    }
}
