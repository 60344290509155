.card {
    @extend .raised-light-4;

    app-tail {
        fill: #fff;
        position: absolute;

        &.up {
            bottom: 100%;
            left: 50%;
        }

        &.right {
            left: 100%;
            top: 50%;
        }

        &.down {
            top: 100%;
            left: 50%;
        }

        &.left {
            right: 100%;
            top: 50%;
        }
    }
}

.card-header, .card-footer {
    background: transparent;
}

.card-footer {
    &.spacious {
        line-height: $input-height-sm;
    }
}
