@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,400i,700,900');

@import "variables";
@import "@angular/cdk/overlay-prebuilt.css";
@import "bootstrap";
@import "defaults";
@import "utilities";
@import "colors";
@import "buttons";
@import "overlay";
@import "layout";
@import "forms";
@import "pages";
@import "charts";
@import "ui";
@import "tables";
@import "navigation";
@import "floor-plans";
@import "loading";
@import "feedback";
@import "errors";
@import "notifications";
@import "card";
@import "user";
@import "notes";
@import "print";
@import "filters";
@import "spinner";

body, html {
    font-size: 14px;
}

body {
    position: relative;
}
