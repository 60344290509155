@import "bootstrap/scss/functions";

$grid-gutter-width: 18px;

$font-size-base: 1rem !default;

//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #543CEF;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$teal: #9CECE3;
$red: #e5466f;
$orange: #fd7e14 !default;
$yellow: #d9b71f;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$colors: (
    "indigo": $indigo,
    "teal": $teal,
    "red": $red,
    "yellow": $yellow,
);

$border-color: $gray-300 !default;
$border-color-light: rgba($border-color, .5);

// WS brand color definitions
$gradient-color-1: color("indigo");
$gradient-color-2: color("teal");
$brand-color: mix($gradient-color-1, $gradient-color-2, 66%);
$action-bg: rgba($brand-color, .15);
$action-bg-light: rgba($brand-color, .085);
$deep: desaturate(darken($brand-color, 30%), 18%);
$dark: desaturate(darken($brand-color, 38%), 50%);
$bst: lighten($brand-color, 5%);
$arsana: lighten($gradient-color-2, 8%);
$arsana-deep: $gradient-color-2;
$arsana-link: desaturate(darken($arsana-deep, 30%), 38%);

$theme-colors: (
    "primary": $brand-color,
    "bst": $bst,
    "arsana": $arsana,
    "arsana-deep": $arsana-deep,
    "arsana-link": $arsana-link,
    "arsana-light": desaturate(lighten($arsana-link, 49%), 7%),
    "deep": $deep,
    "dark": $dark,
    "brand": $brand-color,
    "brand-2": $gradient-color-1,
    "brand-3": $gradient-color-2,
    "male": $blue,
    "female": $pink,
    "secondary": $gray-600,
    "neutral": $gray-300,
    "success": hsl(hue($gradient-color-2) - 12deg, 61%, 48%),
    "info": $cyan,
    "warning": $yellow,
    "danger": $red,
    "light": desaturate(lighten($brand-color, 30%), 18%),
    "lighter": desaturate(lighten($brand-color, 31.5%), 18%),
    "light-alpha": rgba(desaturate($brand-color, 18%), .1),
    "white": $white,
);

$body-bg: $gray-100;
$body-color: $gray-900;

// Overlay variables
$overlay-z-index: 1061;
$dialog-z-index: 2000;

// WS brand gradients
$gradient-1: linear-gradient(135deg, $gradient-color-1 0%, $gradient-color-2 100%);

// Custom layout variables
$header-height: 100px;
$content-padding: 4vw;
$content-padding-h: $content-padding;
$content-padding-top: 0px;
$content-padding-bottom: $content-padding;
$sidebar-width: 260px;
$fab-spacing: 2.5%;

// Custom UI variables
$body-ui-color: rgba(0, 0, 0, .5);
$body-ui-hover-color: $body-color;

$ui-neutral-border-color: rgba(0, 0, 0, .05);
$ui-neutral-border-width: 3px;
$ui-neutral-border: $ui-neutral-border-width solid $ui-neutral-border-color;

// Size variants
$font-size-xl: $font-size-base * 1.5;
$font-size-xs: $font-size-base * .65;
$line-height-xs: 1.5;

$input-btn-padding-y-xs: .25rem !default;
$input-btn-padding-x-xs: .5rem !default;
$input-btn-line-height-xs: $line-height-xs !default;

// Components

//$line-height-lg:              1.5 !default;
//$line-height-sm:              1.5 !default;

$border-width: 1px !default;
$border-color: $gray-300 !default;

$border-radius: .25rem !default;
$border-radius-lg: .5rem;
$border-radius-sm: .2rem !default;

// Cards

//$card-spacer-y:                     .75rem !default;
//$card-spacer-x:                     1.25rem !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius-lg;
$card-border-color: rgba($black, .075);
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
//$card-cap-bg:                       rgba($black, .03) !default;
//$card-bg:                           $white !default;
//
//$card-img-overlay-padding:          1.25rem !default;
//
//$card-group-margin:                 ($grid-gutter-width / 2) !default;
//$card-deck-margin:                  $card-group-margin !default;
//
//$card-columns-count:                3 !default;
//$card-columns-gap:                  1.25rem !default;
//$card-columns-margin:               $card-spacer-y !default;

//$zindex-dropdown:                   1000 !default;
//$zindex-sticky:                     1020 !default;
//$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop: $overlay-z-index + 10;
$zindex-modal: $overlay-z-index + 20;
//$zindex-popover:                    1060 !default;
//$zindex-tooltip:                    1070 !default;

// Forms
$input-color: $body-color;
$input-disabled-bg: $gray-100;
$input-placeholder-color: $gray-500;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

//$table-cell-padding:          .75rem !default;
//$table-cell-padding-sm:       .3rem !default;

//$table-color:                 $body-color !default;
//$table-bg:                    null !default;
//$table-accent-bg:             rgba($black, .05) !default;
//$table-hover-color:           $table-color !default;
$table-hover-bg: $action-bg-light;
//$table-active-bg:             $table-hover-bg !default;

@import "bootstrap/scss/variables";

$grid-breakpoints: map-merge($grid-breakpoints, (xxl: 1400px));

$diameter-btn-circle: $input-btn-line-height*1rem + 2*$input-btn-padding-y;
$diameter-btn-circle-lg: $input-btn-line-height-lg*1rem + 2*$input-btn-padding-y-lg;
$diameter-btn-circle-xl: ($input-btn-line-height-lg*1rem + 2*$input-btn-padding-y-lg) * 1.5;
$diameter-btn-circle-sm: $input-btn-line-height-sm*1rem + 2*$input-btn-padding-y-sm;
$diameter-btn-circle-xs: $input-btn-line-height-xs*1rem + 2*$input-btn-padding-y-xs * .5;
