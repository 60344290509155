.error-status {
    color: theme-color(primary);
    font-weight: 900;
    min-height: 100vh;
    margin-top: -2rem;
    margin-bottom: -2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .code {
        font-size: 4em;
    }

    .text {
        font-size: 1.25em;
        text-transform: uppercase;
    }

    @include media-breakpoint-up(md) {
        font-size: 1.5em;
    }
    @include media-breakpoint-up(lg) {
        font-size: 2em;
    }
}
