@use "sass:math";

.progress {
    position: relative;

    .caption {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        font-size: 9px;
        justify-content: center;
        align-items: center;
    }

    .position-absolute {
        line-height: 1rem;
    }
}

.alert {
    p:last-child {
        margin-bottom: 0;
    }
}

.hover-link {
    cursor: pointer;

    &:hover {
        color: $link-hover-color !important;
    }
}

.hover-display-cnt {
    .hover-display {
        opacity: 0;
        transition: all .25s ease-out;

        &.visible {
            opacity: 1;
        }
    }

    &:hover .hover-display {
        opacity: 1;
    }
}

datepicker-select {
    .input-group & {
        flex-grow: 1;

        &:not(:first-child) .form-control {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:not(:last-child) .form-control {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .input-group-lg & {
        .form-control {
            height: $input-height-lg;
            font-size: $font-size-lg;
            line-height: $input-btn-line-height-lg;
            padding-top: $input-padding-y-lg;
            padding-bottom: $input-padding-y-lg;
            border-radius: $input-border-radius-lg;
        }
    }
}

ngb-datepicker-navigation-select {
    flex-direction: row-reverse;
}

.input-group-lg-normal-font {
    .ng-select {
        &, &.ng-select-single {
            .ng-select-container {
                font-size: 1rem;
            }
        }
    }

    .form-control {
        &, datepicker-select & {
            font-size: 1rem !important;
        }
    }

    .btn {
        font-size: 1rem !important;
    }
}

.highlight-item {
    background: $action-bg;
}

.preserve-newline {
    white-space: pre-line;
}

.hide-empty:empty {
    display: none;
}

.prevent-user-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.with-arrow {
    position: relative;

    &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin: -1px -8px;
    }

    &:after {
        @include triangle(0, #fff, 16px, 8px);
    }

    &:before {
        margin-top: 0;
        margin-bottom: 0;
        @include triangle(0, $input-border-color, 16px, 8px);
    }
}

.reviewed {
    color: $gray-700;
}

.completed {
    color: $gray-500;
}

#toast-container > div {
    opacity: 1;
}

.tooltip {
    &.bs-tooltip-top {
        margin-top: -2px;
    }

    &.bs-tooltip-left {
        margin-left: -2px;
    }

    &.bs-tooltip-bottom {
        margin-top: 2px;
    }

    &.bs-tooltip-right {
        margin-left: 2px;
    }

    &.tooltip-primary {
        .tooltip-inner {
            background-color: theme-color(primary);
        }

        &.bs-tooltip-top .arrow:before {
            border-top-color: theme-color(primary);
        }

        &.bs-tooltip-left .arrow:before {
            border-left-color: theme-color(primary);
        }

        &.bs-tooltip-bottom .arrow:before {
            border-bottom-color: theme-color(primary);
        }

        &.bs-tooltip-right .arrow:before {
            border-right-color: theme-color(primary);
        }
    }
}

.no-line-break {
    white-space: nowrap;
}

.dropdown-menu {
    .dropdown-item {
        .icon {
            margin-left: -$dropdown-item-padding-x*0.5;
            margin-right: $dropdown-item-padding-x*0.5;
        }

        &:not(.active):not(.text-danger) .icon {
            color: theme-color(primary);
        }
    }
}

.o-hidden {
    overflow: hidden;
    text-overflow: ellipsis;
}

.prefer-word-break {
    word-break: break-all;
}

.modal-backdrop {
    z-index: $zindex-modal-backdrop !important;
    background-color: rgba(0, 0, 0, 0.0) !important;

}

ngb-modal-backdrop:first-of-type.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.6) !important;
}

$smart-link-padding-v: .25em;
$smart-link-margin-v: -$smart-link-padding-v;
$smart-link-border-radius: $smart-link-padding-v + .75em;
$smart-link-padding-h: $smart-link-border-radius - .25em;

.smart-link {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    background: theme-color(light-alpha);
    padding: $smart-link-padding-v $smart-link-padding-h;
    margin: $smart-link-margin-v 0;
    border-radius: $smart-link-border-radius;
    white-space: nowrap;

    &:first-child {
        margin-left: -$smart-link-padding-h;
    }

    &:last-child {
        margin-right: -$smart-link-padding-h;
    }

    &, & > a {
        display: inline-flex;
        align-items: center;

        &:hover {
            text-decoration: none;
        }
    }

    .status-indicator, fas {
        margin-right: 4px;
    }

    &.smart-link-danger {
        background: rgba(desaturate(theme-color(danger), 18%), .1);

        &, & > a {
            color: theme-color(danger);

            &:hover {
                color: darken(theme-color(danger), 15%);
            }
        }
    }

    &.smart-link-warning {
        background: rgba(desaturate(theme-color(warning), 18%), .1);

        &, & > a {
            color: theme-color(warning);

            &:hover {
                color: darken(theme-color(warning), 15%);
            }
        }
    }

    &.smart-link-info {
        background: rgba(desaturate(theme-color(info), 18%), .1);

        &, & > a {
            color: theme-color(info);

            &:hover {
                color: darken(theme-color(info), 15%);
            }
        }
    }

    &.smart-link-success {
        background: rgba(desaturate(theme-color(success), 18%), .1);

        &, & > a {
            color: theme-color(success);

            &:hover {
                color: darken(theme-color(success), 15%);
            }
        }
    }

    &.break-lines {
        white-space: normal;
    }
}

.smart-link-cnt {
    $row-margin: .25em;
    margin: (-$row-margin + $smart-link-margin-v) (-$smart-link-padding-h) $smart-link-margin-v;

    .smart-link {
        margin: $row-margin 0 0;
    }
}

.status-indicator {
    //$size: .5em;
    $size: 6px;
    display: inline-block;
    width: $size;
    height: $size;
    flex: none;
    border-radius: 50%;
    background: $text-muted;
}

.highlight-text {
    background: $action-bg;
    border-radius: 2px;
}

$slide-panel-padding: 5%;
$slide-panel-padding-top: $slide-panel-padding*0.5;

slide-panel-host, .preview-bubble {
    $padding: $slide-panel-padding;
    $padding-top: $slide-panel-padding-top;

    .feature-top-indication {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 9px;
        z-index: 1;

        .badge {
            position: absolute;
            top: 1px;
            right: 32px;
        }
    }

    .panel-header {
        display: flex;
        min-height: $header-height;
        align-items: center;
        border-bottom: 1px solid $border-color-light;
        padding: map-get($spacers, 3) $padding;
        flex: none;

        .panel-title {
            font-size: $h1-font-size;
            font-weight: 100;
            opacity: .5;
            padding: 0;
            margin: 0 map-get($spacers, 3) 0 0;

            .panel-title-info {
                font-size: .5em;
                margin-left: .5em;
            }
        }

        .add-on {
            margin-left: auto;
            display: flex;
            align-items: center;

            .filter-container {
                flex-wrap: wrap;
                justify-content: flex-end;

                .filter-button {
                    margin-left: 0;
                }
            }
        }
    }

    .panel-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        padding: $padding-top $padding $padding;
    }

    .panel-footer {
        display: flex;
        margin-top: -$padding-top;
        padding: $padding-top $padding;
        border-top: 1px solid $border-color-light;
        align-items: center;
        justify-content: flex-end;

        .btn:not(:last-child) {
            margin-right: 8px;
        }
    }

    .panel-body .panel-footer {
        $padding-rel-w: math.div($padding * 100%, 100% - 2*$padding);
        margin: $padding-rel-w*0.5 $padding-rel-w * -1 $padding-rel-w * -1;
        padding: $padding-rel-w*0.5 $padding-rel-w;
    }
}

.drop-down-inside-table {
    z-index: $zindex-modal + 10 !important;
}

.preview-bubble {
    $border-radius: $input-btn-line-height-xs*1rem*0.5 + $input-btn-padding-y-xs + .25rem;
    $panel-body-padding-bottom: 2em;
    $panel-link-padding-top: $panel-body-padding-bottom+1em;
    $panel-link-padding-bottom: 1em;

    @extend .card;
    @extend .raised-dark-5;
    display: flex;
    flex-direction: column;
    margin: 0 -.5rem;
    background: #fff;
    border: 0;
    font-size: $small-font-size;
    max-width: 380px;
    min-width: 200px;
    max-height: 380px;
    overflow: hidden;
    z-index: $zindex-modal + 10;
    border-radius: $border-radius;

    &.has-open {
        padding-bottom: $panel-link-padding-top + $panel-link-padding-bottom - $panel-body-padding-bottom + $line-height-base * 1em;
    }

    &.scrollable {
        .panel-body {
            overflow-y: auto;
        }
    }

    .panel-header {
        $font-size: $h3-font-size;
        min-height: $font-size * 2.5;
        padding-top: 0;
        padding-bottom: 0;

        .panel-title {
            font-size: $font-size;
        }
    }

    .panel-body {
        padding-bottom: $panel-body-padding-bottom;
        display: block;
    }

    .panel-link {
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        bottom: 0;
        width: 100%;
        padding: $panel-link-padding-top 0 $panel-link-padding-bottom;
        background: linear-gradient(rgba(#fff, 0) 0%, rgba(#fff, 1) 58%);
    }
}

ngb-tooltip-window {
    z-index: $zindex-modal + 11 !important;
}

.tooltip-cnt {
    position: relative;
}

.guide-tooltip {
    left: 0;
    transition: $transition-base;
    visibility: hidden;

    .arrow {
        left: 10px;
    }

    &.show {
        z-index: 99;
        visibility: visible;
    }

    &:not(.guide-tooltip-bottom) {
        bottom: 100%;
        margin-bottom: -4px;
    }

    &.guide-tooltip-bottom {
        top: 100%;
        margin-top: -4px;
    }
}

zxing-scanner.coverVideo {
    position: relative;

    video {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
    }
}

.overflow-auto-xl-down {
    @include media-breakpoint-down(xl) {
        overflow: auto;
    }
}

.parent-category {
    color: rgba(0, 0, 0, .66);

    &:after {
        display: inline-block;
        color: inherit;
        opacity: .5;
        content: '';
        font-size: .66em;
        width: 1em;
        height: 1em;
        padding: 0;
        margin: 0 .5em;
        background: no-repeat 50% 50%;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='currentColor' d='M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z'></path></svg>");
        background-size: 50%;
    }
}

.text-segment {
    &:not(:last-child):after {
        content: ' | ';
    }
}
