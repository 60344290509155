//@import "~@ngx-loading-bar/core/loading-bar.css";

$loading-color: theme-color(brand-3);

ngx-loading-bar {
    .ngx-spinner {
        left: auto !important;
        right: 10px !important;
    }
}
