$spacing-v: map-get($spacers, 2);
$spacing-h: map-get($spacers, 3);

.filter-container {
    display: flex;
    margin-top: -($spacing-v);
    margin-right: -($spacing-h);

    .filter-control, .filter-group, .filter-button, .filter-element {
        margin-top: $spacing-v;
        margin-right: $spacing-h;
    }
}

#appendTarget .filter-container {
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 0;
    margin-right: 0;

    &:not(:first-child) {
        margin-top: 0;
    }

    .filter-control, .filter-group, .filter-button {
        width: 100%;
        margin-right: 0;
    }

    .filter-control {
        margin-top: $spacing-v;
    }

    .filter-button {
        margin-top: map-get($spacers, 3);
    }

    .select-container {
        display: flex;

        select {
            flex-grow: 1;
        }
    }

    app-advanced-input {
        flex-grow: 1;
    }

    .d-flex, ng-select, .body-dropdown-wrapper {
        width: 100%;
    }
}

.filter-control {
    display: flex;
    align-items: center;

    fal {
        margin-left: 0 !important;
    }

    .icon {
        margin-right: map-get($spacers, 1);
        margin-left: 0 !important;
        display: flex;
        align-items: center;
    }
}

.filter-group {
    border: 1px solid $border-color-light;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    padding: 0 0 $spacing-v $spacing-h;

    #appendTarget & {
        flex-direction: column;
        flex-wrap: nowrap;
        padding-right: $spacing-h;
    }
}

.header-bottom-filter-container {
    margin-bottom: map-get($spacers, 3);
}

.filter-element {
    align-self: center;
}

.filter-button {
    margin-left: auto;
    align-self: center;
}

.dd-menu {
    > .responsive-dropdown {
        display: flex;
        flex-direction: column;
        margin: 0 !important;
        > * {
            max-width: 100%;
            flex: 1;
        }
    }
}

